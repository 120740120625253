import React from 'react';
import styles from './pages.module.css';


export const HomePage: React.FC = () => {
    return (
        <div className={styles.pages}>
            <div className={styles.title}>
                <h1>Hello, world!</h1>
                <p className={styles.block}>█</p>
            </div>
            <p>I'm currently a full-stack software engineer at Topline Pro, with previous experience building large-scale user-facing products at Dropbox and Wayfair.</p>
            <p>I have a passion for learning new skills, programming languages, and technologies. I strive to build products with specific attention paid towards developer-user empathy, accessibility, and security.</p>
            <p>Here you'll find work that I've done on personal projects. If anything piques your interest, let me know, and I'd be happy to chat more about it!</p>
        </div>
    );
};
