import React from 'react';
import styles from './pages.module.css';
import eggs from '../../eggs.png';


export const EasterEggPage: React.FC = () => {
    return <div className={`${styles.pages} ${[styles.eggs]}`}>
        <img src={eggs} alt="You found it!" />
    </div>;
};
