import React from 'react';
import styles from './container.module.css';
import me from '../../me.jpg';

import { Sidebar, Link } from '../sidebar/sidebar';
import { Header } from '../header/header';
import { HomePage } from '../pages/home';
import { ProjectsPage } from "../pages/projects";
import { ContactPage } from "../pages/contact";
import { EasterEggPage } from "../pages/easter_egg";

type pages = 'Home' | 'Projects' | 'Contact' | 'EasterEgg';

export const Container: React.FC = () => {
    const [currentPage, setCurrentPage] = React.useState<pages>('Home');

    const links: Link[] = [
        {
            text: 'Home',
            onClick: () => setCurrentPage('Home')
        },
        {
            text: 'Projects',
            onClick: () => setCurrentPage('Projects')
        },
        {
            text: 'Contact',
            onClick: () => setCurrentPage('Contact')
        }
    ];
    return (
      <div className={styles.container}>
          <div className={styles.sidebar}>
              <Sidebar image={me} links={links} />
          </div>
          <div className={styles.content}>
            <div className={styles.banner}>
              <Header />
            </div>
            <div className={styles.page}>
                {currentPage === "Home" && (<HomePage />)}
                {currentPage === "Projects" && (<ProjectsPage />)}
                {currentPage === "Contact" && (<ContactPage />)}
                {currentPage === "EasterEgg" && (<EasterEggPage />)}
            </div>
          </div>
      </div>
    );
};