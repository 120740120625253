import React from 'react';
import styles from './pages.module.css';

export const ProjectsPage: React.FC = () => {
    return <div className={styles.pages}>
        <div className={styles.title}>
            <h1>Projects coming soon!</h1>
            <p className={styles.block}>█</p>
        </div>
    </div>;
};