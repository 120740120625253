import React from 'react';
import styles from './sidebar.module.css';
import github from '../../github-mark.svg';
import linkedin from '../../LinkedIn_icon.svg';

export interface Link {
    text: string;
    onClick: () => void;
}

interface SidebarProps {
    image: string;
    links: Link[];
}

export const Sidebar: React.FC<SidebarProps> = (props) => {
    return (
        <div className={styles.sidebar}>
            <img
                className={styles.profilePic}
                src={props.image}
                alt="My cartoon smiling face"
            />
            <nav className={styles.links}>
                {props.links.map((link, key) => {
                    return <li onClick={() => link.onClick()}>{link.text}</li>;
                })}
            </nav>
            <div className={styles.socials}>
                    <object
                        type="image/svg+xml"
                        data={linkedin}
                    >
                        <a href="https://linkedin.com/in/rachelplante" rel="noopener noreferrer">
                            <img src={linkedin} alt="LinkedIn" width="24px" height="24px" />
                        </a>
                    </object>
                <p>•</p>
                    <object
                        type="image/svg+xml"
                        data={github}
                    >
                        <a href="https://github.com/rplante" rel="noopener noreferrer">
                            <img src={github} alt="GitHub" width="24px" height="24px" />
                        </a>
                    </object>
            </div>
        </div>
    );
};
