import React from 'react';
import styles from './header.module.css';
import banner from '../../banner.png';

export const Header: React.FC = () => {
    return (
      <img
          className={styles.header}
          src={banner} alt={"Banner that displays the name 'Rachel Plante Godsey' in ASCII art in light green text on a dark grey background"}
      />
    );
}
