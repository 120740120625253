import React from 'react';
import styles from './pages.module.css';

export const ContactPage: React.FC = () => {
    return <div className={styles.pages}>
        <div className={styles.title}>
            <h1>Contact</h1>
            <p className={styles.block}>█</p>
        </div>
        <p>Want to get in touch with me? I can be reached by email at:</p>
        <code>rplante.dev [at] gmail [dot] com</code>
        <p>You can also reach me at my linked profiles in the sidebar. :)</p>
    </div>;
};